import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar";
import userStore from "./Components/store";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import { useEffect, useState } from "react";
import axios from "axios";
import { getLink } from "./utils";

function App() {
	const user = userStore((state) => state.user);
	const SignIn = userStore((state) => state.SignIn);

	const [isLoading, setIsLoading] = useState(true);


	const downloadLinks = async () => {
		try {
			axios.get(`${process.env.REACT_APP_BASEURL_API}/api/tabpages?filters[createdBy][username][$eq]=${user.Username}`, {
				headers: {
					Authorization:
					`Bearer ${user.Token}`,
				},
			})
			.then((resp) => {
				SignIn(user.Email, user.Token, user.Username, resp.data?.data);
			});
		} catch (error) {
			
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (user) {
			downloadLinks();
		} else {
			setIsLoading(false);
		}
	}, []);

	if (!user) {
		return <Login />;
	}

	return (
		<div>
			<Navbar />
			{user && !isLoading ? (
				<Routes>
					[
						<Route
							key={"/"}
							path={`/`}
							element={<Home link={user.TabPages?.[0]?.attributes.link} />}
						/>
						,
						{user.TabPages.map((t) => {
							return (
								<Route
									key={t?.id}
									path={`/${getLink(t.attributes)}`}
									element={<Home link={t?.attributes.link} />}
								/>
							);
						})}
						,
						<Route
							key={"/"}
							path={`*`}
							element={<Home link={user.TabPages[0]?.[0]?.attributes.link} />}
						/>
					]
				</Routes>
			) : (
				<Routes>
					<Route
						path="/"
						element={
							<Home
								callbackText={
									"Al momento non sono presenti dati disponibili"
								}
							/>
						}
					/>
				</Routes>
			)}
		</div>
	);
}

export default App;
