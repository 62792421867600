import { Link } from "react-router-dom";
import { getLink } from "../utils";
import "./Navbar.css";
import userStore from "./store";

export default function Navbar() {
	const SignOut = userStore((state) => state.SignOut);
	const tabpages = userStore((state) => state.user.TabPages);

	return (
		<nav className="navbar navbar-expand-lg">
					<div className="container-fluid">
						<Link to={`/${getLink(tabpages?.[0]?.attributes)}`}>
							<img
								src={require("../Images/header_logo.png")}
								alt="logo"
								width="250"
								height="70"
							/>
						</Link>
						<button
							className="navbar-toggler"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarSupportedContent"
							aria-controls="navbarSupportedContent"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon"></span>
						</button>
						<div
							className="collapse navbar-collapse"
							id="navbarSupportedContent"
						>
							<ul className="navbar-nav me-auto mb-2 mb-lg-0">
								{tabpages.map((link, i) => (
									<li key={i} className="nav-item">
										<Link
											to={`${getLink(link.attributes)}`}
											className="nav-link fw-bold"
										>
											{link.attributes.Title.toUpperCase()}
										</Link>
									</li>
								))}
								<li className="nav-item">
									<Link
										to="/"
										className="btn btn-secondary px-3 py-2"
										onClick={(event) => {
											event.preventDefault();
											window.location.href = "/"
											SignOut();
										}}
									>
										LOGOUT
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</nav>
	);
}
