import create from "zustand";
import { persist } from "zustand/middleware";

let userStore = create(
	persist(
		(set) => ({
			user: null,
			SignIn: (email, token, username, tabPages) =>
				set(() => ({
					user: { Email: email, Token: token, Username: username, TabPages: tabPages },
				})),
			SignOut: () =>
				set({
					user: null,
				}),
		}),
		{
			name: "userFerretti",
		},
	),
);

export default userStore;
