import './Home.css';

export default function Home({link, callbackText}) {
	return (
		<div style={{height:"calc(100vh - 125px)"}}>
			{
				link ? 
				<iframe
					title="Home"
					style={{height:"100%"}}
					className="my-3 "
					src={link}
				></iframe>
				: 
				<p className='py-5 text-center'>{callbackText}</p>
			}
		</div>
	);
}
