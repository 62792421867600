import { useState } from "react";
import "./Login.css";
import userStore from "../Components/store";
import axios from "axios";

export default function Login() {
	const SignIn = userStore((state) => state.SignIn);

	const [email, setEmail] = useState("");
	const [pwd, setPwd] = useState("");
	const [error, setError] = useState("");

	return (
		<div className="Login">
			<div className="LoginForm">
				<div className="d-flex justify-content-center align-items-center flex-grow-1">
					<img
						src={require("../Images/header_logo.png")}
						alt="logo"
						width="250"
						height="70"
					/>
				</div>
				<div className="d-flex justify-content-center align-itmes-center flex-grow-1">
					<form
						className="d-flex flex-column justify-content-center align-items-center w-75 h-100"
						onSubmit={(event) => {
							event.preventDefault();
							axios.post(`${process.env.REACT_APP_BASEURL_API}/api/auth/local`, {
								identifier: email,
								password: pwd,
							})
							.then(function (response) {
								axios.get(`${process.env.REACT_APP_BASEURL_API}/api/tabpages?filters[createdBy][username][$eq]=${response.data?.user?.username}`, {
									headers: {
										Authorization:
										`Bearer ${response.data.jwt}`,
									},
								})
								.then((resp) => {
									SignIn(response.data?.user?.email, response.data.jwt, response.data?.user?.username, resp.data?.data);
								});
							})
							.catch(err => {
								setError("Email o password errati");
							});
						}}
					>
						<div className="w-100">Email</div>
						<input
							type="email"
							className="w-100 my-3 py-2"
							required
							onChange={(event) => setEmail(event.target.value)}
						></input>
						<div className="w-100">Password</div>
						<input
							type="password"
							className="w-100 my-3 py-2"
							required
							onChange={(event) => setPwd(event.target.value)}
						></input>
						<button
							type="submit"
							className="btn btn-secondary w-50 mt-5 py-3"
						>
							ACCEDI
						</button>
						<div className="text-center text-red mt-5">
							{error}
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
